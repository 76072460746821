body {
  margin: 0;
  padding: 0;

  height: 100vh;
  width: 100vw;

  overflow: hidden;

  color: rgb(104, 101, 101);

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
}

a {
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}